import { template as template_3a6cdfd13cd646458a95f72fc9d88c3b } from "@ember/template-compiler";
const WelcomeHeader = template_3a6cdfd13cd646458a95f72fc9d88c3b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
