import { template as template_d5d08557628540c9a573efc53327cd97 } from "@ember/template-compiler";
const FKText = template_d5d08557628540c9a573efc53327cd97(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
